<template lang="pug">
.catalog-head
  .catalog-head__cont.cont
    h1.catalog-head__caption.caption Каталог шоколада
</template>

<script>
import '@/assets/styles/components/catalog-head.sass'

export default {
  name: 'CatalogHead'
}
</script>
