<template lang="pug">
header.header
  .header__cont.cont
    span.header__logo(v-if="isHomePage", :class="{'header__logo_hide': isOpenSearch}")
      img.header__logo-image(src="/images/logo.svg", alt="Логотип Музея «Шоколадный Выборг»", title="Логотип Музея «Шоколадный Выборг»", width="110", height="110")
    router-link.header__logo(v-else, :to="{ name: 'Home' }", :class="{'header__logo_hide': isOpenSearch}")
      img.header__logo-image(src="/images/logo.svg", alt="Логотип Музея «Шоколадный Выборг»", title="Логотип Музея «Шоколадный Выборг»", width="110", height="110")
    nav.header__nav(v-if="!isOpenSearch")
      .header__nav-cont
        template(v-for="(link, i) in navLinks", :key="i")
          .header__nav-group(v-if="link.isGroup")
            button.header__nav-button.text(type="button", :class="{ 'header__nav-button_active' : isShowNavSubmenu && currentShownNavSubmenu === link.groupName }", @click="toggleNavSubmenu(link.groupName)") {{link.value}}
            .header__nav-list(:class="{ 'header__nav-list_active' : isShowNavSubmenu && currentShownNavSubmenu === link.groupName }")
              router-link.header__nav-group-link.text(v-for="(item, i) in groupLinks[link.groupName]", :to="{ name: item.pathName }", :key="i", active-class="header__nav-group-link_active") {{item.value}}
          router-link.header__nav-link.header__nav-link_grot.text(
              v-else-if="link.value === 'Грот'",
              :to="{ name: link.pathName }",
              active-class="header__nav-link_active"
            ) ГР
              picture.header__nav-link-picture
                source(srcset="/images/header/header-1_22.webp 1x, /images/header/header-1_44.webp 2x", type="image/webp",  media="(max-width: 1240px)")
                source(srcset="/images/header/header-1_25.webp 1x, /images/header/header-1_38.webp 1.5x", type="image/webp")
                source(srcset="/images/header/header-1_25.png 1x, /images/header/header-1_38.png 1.5x")
                img.header__nav-link-image(src="/images/header/header-1_25.png", alt="Грот", title="Грот", width="25", height="27")
              span.header__nav-link-letter T
          button.header__nav-catalog-button.text(
            v-else-if="link.value === 'Каталог'",
            type="button",
            :class="{ 'header__nav-catalog-button_active' : isShowCatalogMenu }",
            @click="toggleCatalogMenu") {{ link.value }}
          router-link.header__nav-link.text(
              v-else,
              :to="{ name: link.pathName }",
              active-class="header__nav-link_active"
            ) {{ link.value }}
    .header__panel
      template(v-if="!isSearchPage")
        button.header__panel-item.header__panel-item_search(type="button", @click="showSearch", v-if="!isOpenSearch")
        form.header__search-form(v-if="isOpenSearch", @submit.prevent="goToSearchResultsPage")
          .header__search-field
            input.header__search-input.text(type="search", placeholder="Найти...", v-model="query", @input="getAutocompleteResults", @blur="hideInput" ref="input")
            router-link.header__search-submit(v-if="query.length > 2" :to="{ name: 'Search' , query : { query: query }} ")
            button.header__search-close(type="button", @click="clearSearch")
          .header__search-list(v-if="searchProducts.length && response.status === 'success'")
            router-link.header__product-link(
              v-for="(product, i) in searchProducts",
              :key="i",
              :to="{ name: 'Product', params: { slug: product.slug } }"
              )
              picture.header__picture
                source(:srcset="`${product.image.w30} 1x, ${product.image.w60} 2x`", type="image/webp", media="(max-width: 1240px)")
                source(:srcset="`${product.image.w44} 1x, ${product.image.w88} 2x`", type="image/webp")
                source(:srcset="`${product.image.j44} 1x, ${product.image.j88} 2x`")
                img.header__image(:src="product.image.j44", :alt="`${product.name} - фото`", :title="`${product.name} - фото`", loading="lazy", width="44", height="44")
              .header__product-info
                span.header__product-name {{product.name}}
                span.header__price {{product.price_current}}
      template(v-if="!isOpenSearch")
        router-link.header__panel-item.header__panel-item_cabinet(:to="{ name: 'Login' }", active-class="header__panel-item_active" )
        router-link.header__panel-item.header__panel-item_favorites(:to="{ name: 'Favorites' }", active-class="header__panel-item_active")
        router-link.header__panel-item.header__panel-item_basket(:to="{ name: 'Basket' }", active-class="header__panel-item_active")
          span.header__amount(
              v-if="user && user.basket_count",
              :class="{ 'header__amount_many' : user.basket_count > 99 }"
            ) {{ user.basket_count }}
        .header__burger(@click="this.$emit('open-mobile-menu')")
          .header__burger-line
</template>

<script>
import '@/assets/styles/components/header.sass'
import TheSearch from '../search/TheSearch'
import Storage from '../../assets/scripts/Storage'
import { getSearchResults } from '../../assets/scripts/API'

export default {
  name: 'TheHeader',
  components: { TheSearch },
  props: {
    isShowCatalogMenuInit: {
      type: Boolean
    }
  },
  data () {
    return {
      user: null,
      query: '',
      navLinks: [
        { pathName: 'Catalog', value: 'Каталог' },
        { pathName: 'OnOrder', value: 'Подарки для фирм' },
        { value: 'Покупателям', isGroup: true, groupName: 'buyers' },
        { value: 'Мероприятия', isGroup: true, groupName: 'event' },
        { value: 'Компания', isGroup: true, groupName: 'company' },
        { pathName: 'Grot', value: 'Грот' }
      ],
      groupLinks: {
        buyers: [
          { pathName: 'Delivery', value: 'Доставка' },
          { pathName: 'Payment', value: 'Оплата' }
        ],
        event: [
          { pathName: 'Excursion', value: 'Экскурсии' },
          { pathName: 'Master', value: 'Мастер-классы' },
          { pathName: 'Degustations', value: 'Дегустации' },
          { pathName: 'Birthdays', value: 'Дни рождения' }
        ],
        company: [
          { pathName: 'Contacts', value: 'Контакты' },
          { pathName: 'Requisites', value: 'Реквизиты' }
        ]
      },
      isShowNavSubmenu: false,
      currentShownNavSubmenu: null,
      isShowCatalogMenu: false,
      isHomePage: false,
      isSearchPage: false,
      isOpenSearch: false,
      searchProducts: [],
      response: {}
    }
  },
  emits: ['toggle-catalog-menu', 'close-catalog-menu', 'open-mobile-menu'],
  methods: {
    showSearch () {
      this.isOpenSearch = true
      this.$emit('close-catalog-menu')
      this.$nextTick(() => {
        this.$refs.input.focus()
      })
    },
    hideSearch () {
      this.isOpenSearch = false
    },
    clearSearch () {
      this.clearInput()
      this.searchProducts = []
    },
    refreshUser () {
      this.user = Storage.user
    },
    clearInput () {
      this.query = ''
      this.searchProducts = []
      this.hideSearch()
    },
    hideInput () {
      if (this.query === '') {
        this.hideSearch()
      }
    },
    getAutocompleteResults () {
      const query = this.query
      this.timeOut(query)
    },
    timeOut (query) {
      setTimeout(() => {
        if (query === this.query) {
          this.sendRequest(query)
        }
      }, 300)
    },
    async sendRequest (query) {
      if (query && query.length > 2) {
        this.response = await getSearchResults(query)
        this.searchProducts = this.response.products || []
      } else {
        this.searchProducts = []
      }
    },
    goToSearchResultsPage () {
      this.$router.push({ name: 'Search', query: { query: this.query } })
    },
    toggleNavSubmenu (submenuName) {
      if (window.innerWidth <= 1240) {
        this.isShowNavSubmenu = !this.isShowNavSubmenu
        if (!this.isShowNavSubmenu) this.currentShownNavSubmenu = null
        else this.currentShownNavSubmenu = submenuName
      }
    },
    hideNavSubmenu () {
      this.isShowNavSubmenu = false
      this.currentShownNavSubmenu = null
    },
    closeSubmenu (event) {
      if (!event.target.closest('.header__nav-group')) {
        this.hideNavSubmenu()
      }
    },
    toggleCatalogMenu () {
      if (window.innerWidth > 1240) {
        this.isShowCatalogMenu = !this.isShowCatalogMenu
        this.$emit('toggle-catalog-menu')
      }
    }
  },
  mounted () {
    this.refreshUser()
    window.addEventListener('storageRefresh', this.refreshUser)
    window.addEventListener('click', this.closeSubmenu)
  },
  deactivated () {
    window.removeEventListener('storageRefresh', this.refreshUser)
    window.removeEventListener('click', this.closeSubmenu)
  },
  watch: {
    '$route.name' () {
      this.isHomePage = this.$route.name === 'Home'
      this.isSearchPage = this.$route.name === 'Search'
      this.hideSearch()
      this.clearInput()
      this.hideNavSubmenu()
      this.$emit('close-catalog-menu')
      this.searchProducts = []
    },
    isShowCatalogMenuInit (value) {
      this.isShowCatalogMenu = value
    }
  }
}
</script>
