<template lang="pug">
  .mobile-menu(:class="{ 'mobile-menu_show': isShow, 'mobile-menu_hide': isAnimate  }")
    .mobile-menu__layout(@click="closeMobileMenu")
    .mobile-menu__wrapper
      .mobile-menu__buttons
        .mobile-menu__back(v-if="currentSubmenu", role="button", aria-label="Назад", @click="closeSubmenu")
        .mobile-menu__close(role="button", aria-label="Закрыть мобильное меню", @click="closeMobileMenu")

      .mobile-menu__main
        ul.mobile-menu__list
          li.mobile-menu__item(v-for="(item, i) in menuList", :key="i")
            template(v-if="item.pathName")
              router-link.mobile-menu__link.mobile-menu__link_grot.text(
                v-if="item.name === 'Грот'",
                :to="{ name: item.pathName }",
                active-class="mobile-menu__link_active"
              ) ГР
                picture.mobile-menu__link-picture
                  source(srcset="/images/header/header-1_22.webp 1x, /images/header/header-1_44.webp 2x", type="image/webp",  media="(max-width: 1240px)")
                  source(srcset="/images/header/header-1_25.webp 1x, /images/header/header-1_38.webp 1.5x", type="image/webp")
                  source(srcset="/images/header/header-1_25.png 1x, /images/header/header-1_38.png 1.5x")
                  img.mobile-menu__link-image(src="/images/header/header-1_25.png", alt="Грот", title="Грот", width="25", height="27")
                span.mobile-menu__link-letter T
              router-link.mobile-menu__link.text(
                v-else,
                :to="{ name: item.pathName }",
                active-class="mobile-menu__link_active"
              ) {{ item.name }}
            button.mobile-menu__button.text(v-else, type="button", @click="openSubmenu(item.submenuName)") {{ item.name }}

        ul.mobile-menu__user-list
          template(v-if="user && user.is_auth")
            li.mobile-menu__user-item
              router-link.mobile-menu__user-link.text(
                v-for="(link, i) in userLinks",
                :key="i",
                :to="{ name: link.pathName }",
                active-class="mobile-menu__user-link_active"
              ) {{ link.name }}
          li.mobile-menu__user-item(v-else)
            router-link.mobile-menu__user-link.mobile-menu__user-link_login.text(
              :to="{ name: 'Login' }"
              active-class="mobile-menu__user-link_active"
              ) Мой кабинет

      .mobile-menu__box(
        v-for="(submenu, key, i) in submenuList",
        :key="i"
        :class="{ 'mobile-menu__box_active': key === currentSubmenu }"
      )
        ul.mobile-menu__list
          li.mobile-menu__item(v-for="(item, i) in submenu", :key="i")
            router-link.mobile-menu__link.text(
              :to="{ name: item.pathName }",
              active-class="mobile-menu__link_active"
            ) {{ item.name }}

      .mobile-menu__box(:class="{ 'mobile-menu__box_active': 'catalog' === currentSubmenu }")
        ul.mobile-menu__list
          li.mobile-menu__item(
            v-for="(item, i) in catalogList.map(item => { return item.headline })",
            :key="i"
          )
            button.mobile-menu__button.text(type="button", @click="openSubmenu('catalog', i + 1)") {{ item }}

      .mobile-menu__box(
        v-for="(item, i) in catalogList",
        :key="i"
        :class="{ 'mobile-menu__box_active': currentSubmenu === 'catalog' && currentCategory === i + 1 }"
      )
        ul.mobile-menu__list
          li.mobile-menu__item(
            v-for="(category, j) in item.categoryList",
            :key="j"
          )
            router-link.mobile-menu__link.text(
              v-if="category.slug",
              :to="{ name: 'Category', params: { slug: category.slug } }",
              exact-active-class="mobile-menu__link_active"
            ) {{ category.name }}
            router-link.mobile-menu__link.text(
              v-else,
              :to="{ name: 'Catalog' }",
              exact-active-class="mobile-menu__link_active"
            ) {{ category.name }}
</template>

<script>
import '@/assets/styles/components/mobile-menu.sass'
import { getUser } from '../../assets/scripts/API'

export default {
  name: 'MobileMenu',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    catalogList: Array
  },
  emits: ['close-mobile-menu'],
  data () {
    return {
      isAnimate: false,
      user: null,
      menuList: [
        { name: 'Каталог', submenuName: 'catalog' },
        { name: 'Подарки для фирм', pathName: 'OnOrder' },
        { name: 'Покупателям', submenuName: 'buyers' },
        { name: 'Мероприятия', submenuName: 'event' },
        { name: 'Компания', submenuName: 'company' },
        { name: 'Франшиза', pathName: 'Franchise' },
        { name: 'Грот', pathName: 'Grot' }
      ],
      submenuList: {
        buyers: [
          { name: 'Доставка', pathName: 'Delivery' },
          { name: 'Оплата', pathName: 'Payment' }
        ],
        event: [
          { name: 'Экскурсии', pathName: 'Excursion' },
          { name: 'Мастер-классы', pathName: 'Master' },
          { name: 'Дегустации', pathName: 'Degustations' },
          { name: 'Дни рождения', pathName: 'Birthdays' }
        ],
        company: [
          { name: 'Контакты', pathName: 'Contacts' },
          { name: 'Реквизиты', pathName: 'Requisites' }
        ]
      },
      currentSubmenu: null,
      currentCategory: null,
      userLinks: [
        { name: 'Мои заказы', pathName: 'UserOrders' },
        { name: 'Личные данные', pathName: 'UserInfo' },
        { name: 'Выйти из кабинета', pathName: 'UserLogout' }
      ]
    }
  },
  methods: {
    openSubmenu (submenuName, categoryId = null) {
      this.currentSubmenu = submenuName
      if (categoryId) this.currentCategory = categoryId
    },
    closeSubmenu () {
      if (this.currentCategory) {
        this.currentSubmenu = 'catalog'
        this.currentCategory = null
      } else this.currentSubmenu = null
    },
    closeMobileMenu () {
      this.isAnimate = true
      setTimeout(() => {
        this.currentSubmenu = null
        this.currentCategory = null
        this.$emit('close-mobile-menu')
        this.isAnimate = false
      }, 100)
    }
  },
  watch: {
    '$route.name' () {
      this.closeMobileMenu()
    }
  },
  async mounted () {
    this.user = await getUser()
  }
}
</script>
