import req from './request'
import Storage from './Storage'

const url = process.env.VUE_APP_API

export async function login (email, password) {
  const res = await req.post(`${url}cabinet/login`, { email, password })
  Storage.clearAll()
  Storage.user = res.user
  return res
}

export async function logout () {
  return req.post(`${url}cabinet/logout`)
}

export async function registration (email, password, name, phone) {
  return req.post(`${url}cabinet/register`, { email, password, name, phone })
}

export async function passwordForgot (email) {
  return req.post(`${url}cabinet/password-forgot`, { email })
}

export async function consultation (name, phone) {
  return req.post(`${url}design-project`, { name, phone })
}

export async function refreshUser () {
  const data = await req.post(`${url}cabinet/user-info`)
  Storage.user = data
  return data
}

export function refreshUserLazy () {
  req.post(`${url}cabinet/user-info`).then(data => {
    Storage.user = data
  })
}

export async function getUser () {
  const data = Storage.user
  if (data) return data
  const res = await req.post(`${url}cabinet/user-info`)
  Storage.user = res
  return res
}

export async function setAdultUser () {
  const res = await req.post(`${url}cabinet/user-is-adult`)
  Storage.user = res.user
  return res
}

export async function updateUser (email, password, name, phone) {
  const res = await req.post(`${url}cabinet/update-user`, { email, password, name, phone })
  Storage.user = res.user
  return res
}

export function getBasket () {
  return req.post(`${url}cart/get`)
}

export function addProduct (id, count, offerId) {
  return req.post(`${url}cart/set`, { id, count, offer_id: offerId })
}

export function setProduct (id, count, comment, typeId) {
  return req.post(`${url}cart/set`, { id, count, comment, type_id: typeId })
}

export async function removeProduct (id, typeId) {
  return req.post(`${url}cart/set`, { id, count: 0, type_id: typeId })
}

export async function basketCheckout (products, deliveryId, name, email, phone) {
  return req.post(`${url}cart/checkout`, { products, delivery_id: deliveryId, name, email, phone })
}

export async function getBasketHistory () {
  return req.post(`${url}orders`)
}

export async function getPopularProductsForTheHome () {
  const data = Storage.popProdsForTheHome
  if (data) return data
  const res = await req.post(`${url}products`, { limit: 6, is_popular: 1 }) || []
  if (!Storage.user || Storage.user.role === 'user') Storage.popProdsForTheHome = res
  return res
}

export async function getMainCategoryProductsForTheHome () {
  const data = Storage.mainCategoryProdsForTheHome
  if (data) return data
  const res = await req.post(`${url}main-category`) || {}
  if (!Storage.user || Storage.user.role === 'user') Storage.mainCategoryProdsForTheHome = res
  return res
}

export async function getProduct (slug) {
  const data = Storage.getProduct(slug)
  if (data) return data
  const res = await req.post(`${url}product`, { slug })
  if (!Storage.user || Storage.user.role === 'user') Storage.setProduct(slug, res)
  return res
}

export async function refreshProduct (slug) {
  const res = await req.post(`${url}product`, { slug })
  if (!Storage.user || Storage.user.role === 'user') Storage.setProduct(slug, res)
  return res
}

export async function getProductsCategory (slug) {
  const data = Storage.getCategory(slug)
  if (data) return data
  const res = await req.post(`${url}products`, { category_slug: slug }) || []
  if (res.status !== 'error' && (!Storage.user || Storage.user.role === 'user')) {
    Storage.setCategory(slug, res)
  }
  return res
}

export async function getProductsCatalog (isAdult) {
  const adult = isAdult ? 1 : 0
  const data = Storage.catalog
  if (data) return data
  const res = await req.post(`${url}catalog`, { user_is_adult: adult })
  if (!Storage.user || Storage.user.role === 'user') Storage.catalog = res
  return res
}

export async function getCategories () {
  const data = Storage.categories
  if (data) return data
  const res = await req.post(`${url}categories`)
  if (!Storage.user || Storage.user.role === 'user') Storage.categories = res
  return res
}

export async function verifyEmail (token) {
  return req.post(`${url}cabinet/verify-email`, { token })
}

export function getFavorites () {
  return req.post(`${url}favorites/get`)
}

export function addInFavorites (id) {
  return req.post(`${url}favorites/add`, { id })
}

export async function removeFromFavorites (id) {
  return req.post(`${url}favorites/delete`, { id })
}

export async function getSearchResults (query) {
  return await req.post(`${url}products`, { search: query }) || []
}
