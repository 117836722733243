class Storage {
  #hash = 'VG4C3Z'
  getSS (name) {
    const item = sessionStorage.getItem(`${this.#hash}_${name}`)
    if (item) return JSON.parse(item)
    return null
  }

  setSS (name, data) {
    sessionStorage.setItem(`${this.#hash}_${name}`, JSON.stringify(data))
  }

  clearSS (name) {
    sessionStorage.removeItem(`${this.#hash}_${name}`)
  }

  clearAll () {
    sessionStorage.clear()
  }

  refresh () {
    const event = new Event('storageRefresh')
    window.dispatchEvent(event)
  }

  get showThanks () {
    return this.getSS('thanks')
  }

  set showThanks (data) {
    this.setSS('thanks', data)
  }

  get user () {
    return this.getSS('user')
  }

  set user (data) {
    this.setSS('user', data)
    this.refresh()
  }

  get popProdsForTheHome () {
    return this.getSS('pop-prods-for-the-home')
  }

  set popProdsForTheHome (data) {
    this.setSS('pop-prods-for-the-home', data)
  }

  get newProdsForTheHome () {
    return this.getSS('new-prods-for-the-home')
  }

  set newProdsForTheHome (data) {
    this.setSS('new-prods-for-the-home', data)
  }

  get mainCategoryProdsForTheHome () {
    return this.getSS('main-category-prods-for-the-home')
  }

  set mainCategoryProdsForTheHome (data) {
    this.setSS('main-category-prods-for-the-home', data)
  }

  get catalog () {
    return this.getSS('catalog')
  }

  set catalog (data) {
    this.setSS('catalog', data)
  }

  clearCatalog () {
    this.clearSS('catalog')
  }

  get categories () {
    return this.getSS('categories')
  }

  set categories (data) {
    this.setSS('categories', data)
  }

  getCategory (slug) {
    return this.getSS(`c-${slug}`)
  }

  setCategory (slug, data) {
    return this.setSS(`c-${slug}`, data)
  }

  getProduct (slug) {
    return this.getSS(`p-${slug}`)
  }

  setProduct (slug, data) {
    return this.setSS(`p-${slug}`, data)
  }
}

export default new Storage()
