<template lang="pug">
.category-head
  .category-head__cont.cont
    .category-head__crumbs
      crumbs
      schema-org-crumbs
    h1.category-head__caption.title {{ caption }}
</template>

<script>
import '@/assets/styles/components/category-head.sass'
import Crumbs from '../_tools/Crumbs'
import SchemaOrgCrumbs from '../_tools/SchemaOrgCrumbs'

export default {
  name: 'CategoryHead',
  components: { Crumbs, SchemaOrgCrumbs },
  props: {
    caption: {
      type: String
    },
    categorySlug: {
      type: String
    },
    categoryName: {
      type: String
    }
  }
}
</script>
