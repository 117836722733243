<template lang="pug">
the-header(:is-show-catalog-menu-init="isShowCatalogMenu", @toggle-catalog-menu="toggleCatalogMenu", @close-catalog-menu="closeCatalogMenu", @open-mobile-menu="openMobileMenu")
mobile-menu(
  :catalog-list="categories",
  :is-show="isShowMobileMenu",
  @close-mobile-menu="closeMobileMenu"
)
catalog-menu(
  v-if="categories.length",
  :catalog-list="categories",
  :is-show="isShowCatalogMenu",
  @close-catalog-menu="closeCatalogMenu"
)
.wrapper
  router-view
the-footer
</template>

<script>
import '@/assets/styles/tools/app.sass'
import '@/assets/styles/tools/buttons.sass'
import '@/assets/styles/tools/fonts.sass'
import '@/assets/styles/tools/framework.sass'
import '@/assets/styles/tools/inputs.sass'
import '@/assets/styles/tools/links.sass'
import TheHeader from './components/_layout/TheHeader'
import MobileMenu from './components/_layout/MobileMenu'
import CatalogMenu from './components/_layout/CatalogMenu'
import TheFooter from './components/_layout/TheFooter'

import { getCategories } from './assets/scripts/API'

export default {
  name: 'App',
  components: { TheHeader, MobileMenu, CatalogMenu, TheFooter },
  data () {
    return {
      isShowMobileMenu: false,
      isShowCatalogMenu: false,
      categories: []
    }
  },
  methods: {
    toggleCatalogMenu () {
      if (window.innerWidth > 1240) {
        this.isShowCatalogMenu = !this.isShowCatalogMenu
      }
    },
    closeCatalogMenu () {
      if (window.innerWidth > 1240) {
        this.isShowCatalogMenu = false
      }
    },
    openMobileMenu () {
      if (window.innerWidth <= 1240) {
        this.isShowMobileMenu = true
      }
    },
    closeMobileMenu () {
      if (window.innerWidth <= 1240) {
        this.isShowMobileMenu = false
      }
    }
  },
  mounted () {
    getCategories().then(data => { this.categories = data })
  },
  async beforeRouteEnter (to, from, next) {
    await getCategories()
    next()
  }
}
</script>
