<template lang='pug'>
.excursions-preview
  .excursions-preview__cont.cont
    h2.excursions-preview__caption.caption
      | Экскурсии
    p.excursions-preview__text.text-big
      | Вас ждет рассказ о происхождении шоколада, из чего состоит настоящий шоколад и
      | сколько какао-бобов нужно для одной плитки.
    p.excursions-preview__text.text-big
      | Наши экскурсии — это не только знания, но и дегустация, советы по хранению и
      | секреты отличия настоящего шоколада от подделок!
    router-link.excursions-preview__more(:to="{ name: 'Excursion'}")
      span.excursions-preview__more-text.link-more Подробнее об экскурсиях
    .excursions-preview__card
      span.excursions-preview__card-title.title Погрузитесь в шоколадное путешествие
      p.excursions-preview__card-text.text Узнайте секреты шоколада и попробуйте его уникальные вкусы
      ul.excursions-preview__card-list
        li.excursions-preview__card-item.text-big Для туроператоров
        li.excursions-preview__card-item.text-big Для гостей города
        li.excursions-preview__card-item.text-big Семейная экскурсия
      p.excursions-preview__card-note.text Запишитесь на экскурсию уже сегодня и сделайте день незабываемым!
      button.excursions-preview__card-button.button-white(@click="this.$emit('open-modal')") Записаться на экскурсию
      picture.excursions-preview__card-picture
        source(srcset="/images/excursions-preview/excursions-preview-1_318.webp 1x, /images/excursions-preview/excursions-preview-1_636.webp 2x", type="image/webp", media="(max-width: 520px)")
        source(srcset="/images/excursions-preview/excursions-preview-1_458.webp 1x, /images/excursions-preview/excursions-preview-1_916.webp 2x", type="image/webp", media="(max-width: 950px)")
        source(srcset="/images/excursions-preview/excursions-preview-1_458.png 1x, /images/excursions-preview/excursions-preview-1_916.png 2x", media="(max-width: 950px)")
        source(srcset="/images/excursions-preview/excursions-preview-1_350.webp 1x, /images/excursions-preview/excursions-preview-1_700.webp 2x", type="image/webp", media="(max-width: 1240px)")
        source(srcset="/images/excursions-preview/excursions-preview-1_457.webp 1x, /images/excursions-preview/excursions-preview-1_686.webp 1.5x", type="image/webp")
        source(srcset="/images/excursions-preview/excursions-preview-1_457.png 1x, /images/excursions-preview/excursions-preview-1_686.png 1.5x")
        img.index-nav__image(src="/images/excursions-preview/excursions-preview-1_457.png", loading="lazy", alt="", title="", width="452", height="502")
</template>

<script>
import '@/assets/styles/components/excursions-preview.sass'

export default {
  name: 'ExcursionsPreview',
  emits: ['open-modal']
}
</script>
