<template lang="pug">
router-view
</template>

<script>

export default {
  name: 'CatalogPage'
}
</script>
