<template lang="pug">
  .catalog-menu(:class="{ 'catalog-menu_show': isShow || isAnimate, 'catalog-menu_hide': isAnimate }")
    .catalog-menu__layout(@click="closeCatalogMenu")
    .catalog-menu__wrapper
      .catalog-menu__cont.cont
        .catalog-menu__list
          .catalog-menu__item(v-for="(item, i) in catalogList", :key="i")
            span.catalog-menu__headline {{ item.headline }}
            ul.catalog-menu__category-list
              li.catalog-menu__category-item(v-for="(category, i) in item.categoryList", :key="i")
                router-link.catalog-menu__category-link.text(
                  v-if="category.slug",
                  :to="{ name: 'Category', params: { slug: category.slug } }",
                  exact-active-class="catalog-menu__category-link_active",
                  @click="closeCatalogMenu"
                ) {{ category.name }}
                router-link.catalog-menu__category-link.text(
                  v-else,
                  :to="{ name: 'Catalog' }",
                  exact-active-class="catalog-menu__category-link_active",
                  @click="closeCatalogMenu"
                ) {{ category.name }}
</template>

<script>
import '@/assets/styles/components/catalog-menu.sass'

export default {
  name: 'CatalogMenu',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    catalogList: Array
  },
  emits: ['close-catalog-menu'],
  data () {
    return {
      isAnimate: false
    }
  },
  methods: {
    closeCatalogMenu () {
      this.isAnimate = true
      setTimeout(() => {
        this.$emit('close-catalog-menu')
        this.isAnimate = false
      }, 100)
    }
  },
  watch: {
    isShow (value) {
      if (!value) {
        this.isAnimate = true
        setTimeout(() => {
          this.isAnimate = false
        }, 100)
      }
    }
  }
}
</script>
