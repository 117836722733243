<template lang='pug'>
.modal-excursions(v-if="isShow")
  .modal-excursions_inner
    .modal-excursions__layer(@click="this.$emit('close-modal')")
    .modal-excursions__cont
      .modal-excursions__close(role="button", aria-label="Закрыть модально окно Запись на экскурсию", @click="this.$emit('close-modal')")
      h2.modal-excursions__title.title Запись на экскурсию
      span.modal-excursions__text.text Предварительная запись обязательна
      span.modal-excursions__note.text Свяжитесь с нами по телефону или WhatsApp
      .modal-excursions__contacts
        a.modal-excursions__contacts-link.title(:href="phone.general.link") {{phone.general.mask}}
        .modal-excursions__social-box
          a.modal-excursions__social(:class="`modal-excursions__social_${whatsAppLink.mod}`", :href="whatsAppLink.link", :title="whatsAppLink.name", target="_blank", rel="noopener nofollow noreferrer")
</template>

<script>
import '@/assets/styles/components/modal-excursions.sass'
import { phone } from '../../assets/scripts/variables'
import { whatsAppLink } from '../../assets/scripts/socials'

export default {
  name: 'TheModalExcursions',
  props: {
    isShow: {
      type: Boolean
    }
  },
  emits: ['close-modal'],
  data () {
    return {
      phone,
      whatsAppLink
    }
  }
}
</script>
